import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const CookiePolicyPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>Cookie Policy | AI Product Reviews</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>Last Updated: September 20, 2023</p>
            <h2>1. Introduction</h2>
            <p>
              Welcome to
              <a
                href="https://ai-productreviews.com/"
                target="_blank"
                rel="noopener"
              >
                {" "}
                https://ai-productreviews.com/
              </a>
              , operated by PJK MARKETING LTD, located at Hope Cottage Draycott,
              Claverley, Wolverhampton, Shropshire, England, WV5 7EA. In this
              Cookie Policy, we explain how we use cookies and similar tracking
              technologies on our website (referred to as "the Website"). This
              policy aims to help you understand what cookies are, how we use
              them, and how you can manage your cookie preferences.
            </p>
            <h2>2. What Are Cookies?</h2>
            <p>
              Cookies are small text files that are stored on your device
              (computer, tablet, or mobile phone) when you visit a website. They
              are widely used to enable website functionality, enhance user
              experience, and provide valuable information to website owners.
            </p>
            <h2>3. How We Use Cookies</h2>
            <p>We use cookies for various purposes, including:</p>
            <ul>
              <li>
                Essential Cookies: These cookies are necessary for the operation
                of the Website. They enable you to navigate the site and use its
                features, such as accessing secure areas.
              </li>
              <li>
                Analytical/Performance Cookies: We use these cookies to analyze
                how visitors use the Website, assess its performance, and
                improve user experience. These cookies help us understand which
                pages are most and least popular and how visitors move around
                the site.
              </li>
              <li>
                Functionality Cookies: Functionality cookies allow the Website
                to remember your preferences (such as language or region) and
                provide enhanced features. These cookies may also be used to
                remember changes you have made to text size, fonts, and other
                customizable elements.
              </li>
              <li>
                Targeting/Advertising Cookies: Our advertising partners may use
                these cookies to deliver relevant ads based on your interests.
                These cookies also help measure the effectiveness of advertising
                campaigns.
              </li>
            </ul>
            <h2>4. Your Cookie Choices</h2>
            <p>
              Most web browsers automatically accept cookies, but you can manage
              your cookie preferences by adjusting your browser settings. You
              can usually find these settings in the "Options" or "Preferences"
              menu of your browser. You can choose to accept or reject cookies,
              delete specific cookies, or set your browser to notify you when a
              cookie is sent. Please note that if you choose to disable cookies,
              some parts of the Website may not function correctly.
            </p>
            <h2>5. Contact Us</h2>
            <p>
              If you have any questions or concerns about our Cookie Policy,
              please contact us at:
            </p>
            <p>
              PJK MARKETING LTDHope Cottage Draycott, Claverley,Wolverhampton,
              Shropshire,England, WV5 7EAEmail:{" "}
              <a
                href="mailto:info@ai-productreviews.com"
                target="_blank"
                rel="noopener"
              >
                info@ai-productreviews.com
              </a>
            </p>
            <p>
              We may update this Cookie Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. We encourage you to review this policy
              periodically for any updates.
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default CookiePolicyPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Cookie Policy | AI Product Reviews"}
      description={
        "Our dedication to ethical AI practices. Discover our modern slave statement and responsible AI commitment."
      }
      pathname={location.pathname}
    />
  );
};
